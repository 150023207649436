@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
    content: '';
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the loading spinner */
    z-index: 10010;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Apply the spin animation */
}

/* Spin Animation */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
